import React, { useState } from "react";
import Layout from '../components/layout'
import CssBaseline from '@mui/material/CssBaseline';
import { Card, CardActions, CardActionArea, CardMedia, CardContent, Typography, Box, Input, Grid, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, FormGroup, Container, Snackbar, Alert } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { navigate } from "gatsby"
import {
  Link,
  Button,
} from "gatsby-theme-material-ui";

import img1 from "../images/pexels-pixabay-209954.jpg"
import img2 from "../images/pexels-pixabay-209961.jpg"
import img3 from "../images/pexels-tim-mossholder-9539099.jpg"


const theme = createTheme({
  components: {
    TextField: {
      styleOverrides: {
        root: {
          'margin-top': '1rem'
        },
      },
    }
  }
});


const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
    <Layout pageTitle="Home Page">
      <CssBaseline />
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <Card square>
            <CardActionArea onClick={() => navigate('/newofficial')}>
              <CardMedia
                component="img"
                height="340"
                image={img1}
                alt="Football with referee"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Become a Referee
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Hawaii is always looking for new referees.  If you are interested in becoming a referee, let us know we would love to talk to you about it.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button to="/newofficial" size="small" color="primary">
                Learn about Officiating
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card square>
            <CardActionArea onClick={() => navigate('/videos')}>
              <CardMedia
                component="img"
                height="340"
                image={img2}
                alt="Football player with referee"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Instructional Videos
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Browse our archive of instructional videos to learn more about officiating. If using for your local association, please consider a donation.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button to="/videos" size="small" color="primary">
                Officiating Videos
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card square>
            <CardActionArea onClick={() => navigate('/staff')}>
              <CardMedia
                component="img"
                height="340"
                image={img3}
                alt="Football player with referee"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Clinic Staff
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  An annual officiating clinic is held in the spring.  The clinic is staffed by experienced officials from around the country. Learn about the staff.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button to="/staff" size="small" color="primary">
                Clinic Staff
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
